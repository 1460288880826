import React from 'react';
import {graphql} from 'gatsby';
import Img from 'gatsby-image';
import Helmet from 'react-helmet';
import {
  Container,
  Footer,
  Header,
  Heading,
  Page,
  Section,
  Subheading,
  Gallery,
  VisuallyHidden,
} from '../components';

import './index.css';

export default ({data}) => {
  const images = [
    {
      id: 'image-1',
      source: data.image1.childImageSharp.fluid,
      title: 'Bright modern kitchen with lighting',
    },
    {
      id: 'image-2',
      source: data.image2.childImageSharp.fluid,
      title: 'Living room with wall lighting',
    },
    {
      id: 'image-3',
      source: data.image3.childImageSharp.fluid,
      title: 'Rustic kitchen with lighting',
    },
    {
      id: 'image-4',
      source: data.image4.childImageSharp.fluid,
      title: 'Close up of electrical breaker',
    },
    {
      id: 'image-5',
      source: data.image5.childImageSharp.fluid,
      title: 'Hanging edison bulb lights',
    },
    {
      id: 'image-6',
      source: data.image6.childImageSharp.fluid,
      title: 'Colourful kitchen',
    },
  ];
  
  return (
    <Page>
      <Helmet>
        <meta charSet="utf-8" />
        <title>1up Electric</title>
        <title>1up Electric | Madawaska Valley Electrician | Free Quotes</title>
        <link rel="canonical" href="https://1upelectric.ca" />
        <meta name="description" content="Family Owned, Residential and Commercial Electrician in the Madawaska Valley." />
        <meta name="keywords" content="+barry's bay, eganville, madawaska valley,ottawa valley,electrician, electrical, contractor" />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <html lang="en" />&
      </Helmet>
      <Container>
        <Header background={data.boxes.childImageSharp.fixed}>
          <VisuallyHidden><Heading>1up Electric</Heading></VisuallyHidden>
          <Img fixed={data.logoImage.childImageSharp.fixed} alt='' />
        </Header>
      </Container>
      <Section>
        <Gallery images={images} />
      </Section>
      <Section>
        <Container>
          <Subheading>About us</Subheading>
          <p>1up Electric is a locally owned electrical company serving the Madawaska Valley for all of your electrical needs. All work is completed by a master electrician. So whether you need new installations, repairs, or routine maintenance, we can help.</p>
        </Container>
      </Section>
      <Section>
        <Container>
          <Subheading>Our services</Subheading>
          <p>We offer our services to residential, commercial and industrial clients and can handle any job, large or small.</p>
          <ul>
            <li>Full-service electrical layout, design</li>
            <li>Wiring and installation/upgrades</li>
            <li>Emergency power solutions (generators)</li>
            <li>Panel upgrade</li>
            <li>Custom lighting</li>
            <li>Almost any other electrical work you need. Just ask!</li>
          </ul>
        </Container>
      </Section>
      <Footer />
    </Page>
  );
}

export const query = graphql`
  query MainQuery {
    logoImage: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    boxes: file(relativePath: { eq: "squares-background.png" }) {
      childImageSharp {
        fixed(height: 120) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    image1: file(relativePath: { eq: "photo.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "photo-2.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "photo-3.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image4: file(relativePath: { eq: "photo-4.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image5: file(relativePath: { eq: "photo-5.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image6: file(relativePath: { eq: "photo-6.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

