import React from 'react';
import Img from 'gatsby-image';

import styles from './Header.module.css';

export function Header({background, children}) {
  return (
    <header className={styles.Header}>
      {children}
      <div className={styles.Boxes}>
        <Img fixed={background} alt="" />
      </div>
    </header>
  );
}

