import React from 'react';

import {Subheading} from '../Subheading';

import styles from './Footer.module.css';

export function Footer() {
  return (
    <footer className={styles.Footer}>
      <div className={styles.Contact}>
        <div className={styles.ContactTitle}>
          <Subheading>Get in touch</Subheading>
        </div>
        <p>Email: <a className={styles.Link}  href="mailto:1upElectricLtd@gmail.com">1upElectricLtd@gmail.com</a></p>
        <p>Phone: <a className={styles.Link} href="tel:613-625-1322">613-858-0797</a></p>
      </div>
      <div className={styles.Form}>
        <form method="post" netlify-honeypot="bot-field" data-netlify="true">
          <input type="hidden" name="bot-field" />
          <label className={styles.Field}>
            Name
            <input className={styles.Input} type="text" name="name" id="name" />
          </label>
          <label className={styles.Field}>
            Email
            <input className={styles.Input} type="email" name="email" id="email" />
          </label>
          <label className={styles.Field}>
            Subject
            <input className={styles.Input} type="text" name="subject" id="subject" />
          </label>
          <label className={styles.Field}>
            Message
            <textarea className={styles.Input} name="message" id="message" rows="5" />
          </label>
          <button className={styles.Button} type="submit">Submit</button>
        </form>
      </div>
    </footer>
  );  
}
