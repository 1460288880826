import React, {useRef, useState} from 'react';
import Img from 'gatsby-image';
import classNames from 'classnames';

import {Icon} from '../Icon';
import {debounce} from '../../utilities/debounce';

import styles from './Gallery.module.css';


export function Gallery({images}) {
  const galleryRef = useRef(null);
  const [activeEmbed, setActiveEmbed] = useState(0);
  
  const handleContainerScroll = debounce((event) => {
    if (!galleryRef.current) {
      return;
    }

    const embedWidth = galleryRef.current.firstChild.offsetWidth;
    const scrollPosition = galleryRef.current.scrollLeft;
    
    setActiveEmbed(Math.round(scrollPosition / embedWidth));
  }, 10);

  const imagesMarkup = images.map((image) => {
    return (
      <div className={styles.Embed}>
        <Img sizes={{...image.source, aspectRatio: 21 / 9}} alt={image.title} objectPosition="center center" />
      </div>
    );
  });
  
  const scrollLeft = () => {
    const scrollPosition = galleryRef.current.scrollLeft;
    const imageWidth = galleryRef.current.firstChild.offsetWidth;
    
    let newScrollPosition;
    if (scrollPosition < 10) {
      newScrollPosition = galleryRef.current.lastChild.offsetLeft;
    } else {
      newScrollPosition = scrollPosition - imageWidth;
    }
    setActiveEmbed(Math.round(newScrollPosition / imageWidth));
    
    galleryRef.current.scrollTo({
      top: 0,
      left: newScrollPosition,
      behavior: 'smooth'
    });
  }
  
  const scrollRight = () => {
    const scrollPosition = galleryRef.current.scrollLeft;
    const imageWidth = galleryRef.current.firstChild.offsetWidth;
    
    let newScrollPosition;
    if (scrollPosition >= galleryRef.current.lastChild.offsetLeft) {
      newScrollPosition = 0;
    } else {
      newScrollPosition = scrollPosition + imageWidth;
    }
    setActiveEmbed(Math.round(newScrollPosition / imageWidth));
 
    galleryRef.current.scrollTo({
      top: 0,
      left: newScrollPosition,
      behavior: 'smooth'
    });
  }

  const indicatorsMarkup = images.map((image, index) => {
    const indicatorClass = classNames(styles.Indicator, {
      [styles.active]: index === activeEmbed
    });

    return <div key={index} className={indicatorClass} />
  });

  return (
    <div className={styles.Gallery}>
      <div className={styles.GalleryContainer}>
        <div
          className={styles.EmbedsContainer}
          ref={galleryRef}
          onScroll={handleContainerScroll}
        >
          {imagesMarkup}
        </div>
        <button type="button" className={styles.Left} onClick={scrollLeft}>
          <Icon name="left" />
        </button>
        <button type="button" className={styles.Right} onClick={scrollRight}>
          <Icon name="right" />
        </button>
      </div>
      <div className={styles.IndicatorsContainer}>
        {indicatorsMarkup}
      </div>
    </div>
  );
}

